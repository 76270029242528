<template>
    <section id="apartments">
    <v-container fluid class="bg-image full-height">
      <v-row class="bg-brown">
        <v-col cols="12" class="py-5">
           <v-container class="light--text text-shadow text-center">
             <v-row>
               <v-col cols="12" class="py-5 display-1">
                  <h1 class="text-uppercase ">{{this.$i18n.t('pages.apartments.title')}}</h1>
               </v-col>
               <v-col cols="12" md="4" class="py-5" v-for="apartment in list" :key="apartment.slug">
                 <v-btn :color="apartment.color" outlined block class="py-5 apartment-btn" @click="goToSingle(apartment)">
                 <div class="d-block py-5 px-3 light--text">
                   <h2 class="text-uppercase">{{apartment.title}}</h2>
                   <p>{{apartment.size}}</p>
                   <v-btn outlined color="light" class="rounded-0" @click="goToSingle(apartment)">{{ $t('commons.actions.come-in') }}</v-btn>
                    <!-- <div>
                      {{$t('commons.prices.start-from')}}
                      <h1 class="py-0">{{apartment.price}}</h1>
                      {{$t('commons.prices.euro-night')}}
                    </div> -->
                 </div>
                 </v-btn>
               </v-col>
             </v-row>
           </v-container>
        </v-col>
      </v-row>
    </v-container>
    <!-- <json-debug :objs="[apartments]"></json-debug> -->
    </section>
</template>


<script>
   import {mapGetters} from "vuex";
  export default {
    name: 'HeroApartments',
    computed: {
     ...mapGetters({
          list: 'apartments/list',
          single: 'apartments/single',
          loading: 'loading/loading',
      }),
     },
    data: () => ({
      apartments: {},
    }),
    created() {
        this.apartments = this.$i18n.t('apartments.list')
        if(!this.list.lenght){
          this.getData()
        }
    },
    methods:{
     async getData(){
          this.$store.dispatch('apartments/getData').then((response) => {
           console.log("RESPONSE", response.data)
          })
     },
     goToSingle(apartment){
          this.$store.dispatch('apartments/setSingle',apartment)
          console.log(apartment.slug)
          this.$router.push({name:'apartments.single',params:{slug:apartment.slug}}) 
     },
    }
  }
</script>


<style scoped>
.bg-image{
  background-image: url('/img/assets/apartments.jpg');
}
.bg-brown {
    background: rgba(159, 127, 102, 0.2);
    background: -webkit-linear-gradient(to bottom, rgba(159, 127, 102, 0.5) , rgba(51, 30, 14, 0.8));
    background: linear-gradient(to bottom, rgba(159, 127, 102, 0.5) , rgba(51, 30, 14, 0.8));
}
.apartment-btn{
  text-transform: none !important;
  height: auto !important;
  font-size: 1.2rem;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"home"}},[_c('hero-header'),_c('title-section',{staticClass:"secondary py-5 light--text",attrs:{"fluid":"","id":"titlesection","title":_vm.$t('pages.home.title'),"content":_vm.$t('pages.home.content'),"action":{text:_vm.$t('pages.home.action.text'),to:{name:'apartments.index'},class:'my-2 text tile', outlined:true, tile:true, color:'light'}}}),_c('hero-apartments'),_c('title-section',{staticClass:"secondary py-5 light--text",attrs:{"fluid":"","id":"titlesection","title":_vm.$t('commons.actions.come-here'),"sub_title":_vm.$t('contacts.address.value'),"content_class":"text-center","action":{
      text:_vm.$t('commons.actions.come-here'),
      icon:_vm.$t('contacts.address.icon'),
      href:_vm.$t('contacts.address.href'),
      target:'_blank',
      class:'my-2 text tile display-2', 
      outlined:true, 
      tile:true, 
      color:'light',
      class:'py-5 x-large mx-auto',
    }}}),_c('hero-contact')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="home">
    <hero-header ></hero-header>
    <title-section 
    fluid 
    class="secondary py-5 light--text" 
    id="titlesection" 
    :title="$t('pages.home.title')" 
    :content="$t('pages.home.content')"
    :action="{text:$t('pages.home.action.text'),to:{name:'apartments.index'},class:'my-2 text tile', outlined:true, tile:true, color:'light'}"
    ></title-section>
    <hero-apartments></hero-apartments>
    <title-section 
    fluid 
    class="secondary py-5 light--text" 
    id="titlesection" 
    :title="$t('commons.actions.come-here')" 
    :sub_title="$t('contacts.address.value')"
    content_class="text-center"
    :action="{
        text:$t('commons.actions.come-here'),
        icon:$t('contacts.address.icon'),
        href:$t('contacts.address.href'),
        target:'_blank',
        class:'my-2 text tile display-2', 
        outlined:true, 
        tile:true, 
        color:'light',
        class:'py-5 x-large mx-auto',
      }"
    ></title-section>
    <hero-contact></hero-contact>

  </div>
</template>

<script>
  import HeroHeader from "@/components/Layout/Sections/HeroHeader"
  import TitleSection from "@/components/Layout/Sections/TitleSection"
  import HeroApartments from "@/components/Layout/Sections/HeroApartments"
  import HeroContact from "@/components/Layout/Sections/HeroContact"

  export default {
    name: 'Home',
    components: {
      "hero-header": HeroHeader,
      "title-section": TitleSection,
      "hero-apartments": HeroApartments,
      "hero-contact": HeroContact
    },
    data: () => ({
      title: process.env.VUE_APP_BASE_NAME,
    }),
  }
</script>
<style scoped>
#home{
  min-height: 100%;
}
</style>
